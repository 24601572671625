

















































































































import { Component, Vue } from 'vue-property-decorator'
import { apiAfterSaleLists } from '@/api/order/order'
import { RequestPaging } from '@/utils/util'
import DatePicker from '@/components/date-picker.vue'
import { AfterSaleType } from '@/utils/type'
import ExportData from '@/components/export-data/index.vue'
import AfterSalesPane from '@/components/order/after-sales-pane.vue'
@Component({
    components: {
        AfterSalesPane,
        DatePicker,
        ExportData
    }
})
export default class AfterSales extends Vue {
    // S Data
    activeName: any = 'all'

    apiAfterSaleLists = apiAfterSaleLists

    tabs = [
        {
            label: '全部',
            name: AfterSaleType[0]
        },
        {
            label: '售后中',
            name: AfterSaleType[1]
        },
        {
            label: '售后完成',
            name: AfterSaleType[2]
        },
        {
            label: '售后取消',
            name: AfterSaleType[3]
        }
    ]

    tabCount = {
        all: 0, //全部
        ing: 0, //售后中
        success: 0, //售后成功
        fail: 0 //售后拒绝
    }

    form: any = {
        after_sale_sn: '', //否	string	售后单号
        order_sn: '', //否	string	订单号
        user_info: '', //否	string	用户编码
        goods_info: '', //否	string	商品编码
        refund_type: '', //否	int	售后类型 1-整单退款 2-商品售后
        refund_method: '', //否	int	售后方式 1-仅退款 2-退货退款
        start_time: '', //否	string	申请开始时间 格式：年月日 时：分：秒
        end_time: '', //否	string	申请结束时间 格式：年月日 时：分：秒
        invoice_no: '' //快递单号
    }

    pager = new RequestPaging()

    // E Data

    // S Methods
    // 获取订单信息
    getAfterSaleLists(page?: number): void {
        page && (this.pager.page = page)
        let status: any = this.activeName == 'all' ? '' : AfterSaleType[this.activeName]
        this.pager
            .request({
                callback: apiAfterSaleLists,
                params: {
                    status,
                    ...this.form
                }
            })
            .then(res => {
                this.tabCount = res?.extend
            })
    }

    // 重置搜索
    reset() {
        Object.keys(this.form).map(key => {
            this.$set(this.form, key, '')
        })
        this.getAfterSaleLists()
    }

    // E Methods

    // S  life cycle

    created() {
        // 获取售后订单信息
        this.getAfterSaleLists()
        // 获取其他方式数据
        // this.getOtherMethodList();
    }

    // E life cycle
}
