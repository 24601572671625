

















































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import LsDialog from "@/components/ls-dialog.vue";
import LsPagination from "@/components/ls-pagination.vue";
import { apiAfterSaleCancel } from "@/api/order/order";
@Component({
    components: {
        LsDialog,
        LsPagination,
    },
})
export default class OrderPane extends Vue {
    @Prop() value: any;
    @Prop() pager!: any;

    // 跳转
    click(event: any) {
        this.$router.push({
            path: "/after_sales/after_sales_detail",
            query: { id: event.id },
        });
    }

    cancelSales(id: any) {
        apiAfterSaleCancel({ order_goods_id: id }).then((res) => {
            this.$emit("refresh");
            this.$message.success("撤销成功!");
        });
    }

    toUser(id: any) {
        this.$router.push({
            path: "/user/user_details",
            query: { id: id },
        });
    }
}
